import React, { createContext, useState } from "react";

export const AlertContext = createContext(null);

const AlertProvider = (props) => {
    const [snack, setSnack] = useState({ state: false, mensaje: "" });

    const stateAlert = (estado, message) => {
        if (estado === "success") {
            setSnack({
                state: true,
                mensaje: message,
                colorbackgr: "#4BB543",
                colortext: "white",
            });
        }

        if (estado === "error") {
            setSnack({
                state: true,
                mensaje: message,
                colorbackgr: "red",
                colortext: "white",
            });
        }

        if (estado === "login") {
            setSnack({
                state: true,
                mensaje: message,
                colorbackgr: "white",
                colortext: "black",
            });
        }

        if (estado === "nologin") {
            setSnack({
                state: true,
                mensaje: message,
                colorbackgr: "red",
                colortext: "white",
            });
        }
    };

    const handleClose = (e) => {
        setSnack((prevState) => ({
            ...prevState,
            state: false,
            mensaje: "",
        }));
    };

    return <AlertContext.Provider value={{ snack, setSnack, stateAlert, handleClose }}>{props.children}</AlertContext.Provider>;
};

export default AlertProvider;
