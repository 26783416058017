import React from "react";
import { CircularProgress } from "@material-ui/core";

const FullPageLoader = () => {
    return (
        <div
            style={{
                position: "fixed",
                width: "100%",
                height: "100%",
                top: "0",
                left: "0",
                background: "#f8f8f8ad",
                zIndex: "1001",
            }}
        >
            <CircularProgress style={{ top: "50%", left: "50%", zIndex: "1001", position: "absolute" }} />
        </div>
    );
};

export default FullPageLoader;
